<template>
    <div>
        <template v-if="system_key !== 'intelligent-elevator'">
            <div :style="'opacity:' + (equip_item.equip_name ? '1' : '0')">
                <b-card-body class="content-sidebar-header">
                    <b-card-title>
                        {{ equip_item.equip_name }}
                    </b-card-title>
                    <b-card-sub-title v-if="equip_item.equip_meter_code">
                        <small>{{ equip_item.equip_meter_code }}</small>
                    </b-card-sub-title>
                </b-card-body>

                <b-button
                    class="btn-icon rounded-circle position-absolute"
                    style="right: 0.5rem; top: 0rem; z-index: 3"
                    variant="flat-primary"
                    :style="'opacity:' + (loading_show ? '0' : '1')"
                    @click="
                        $emit('switchModel');
                        isDetailModel = !isDetailModel;
                    "
                    v-b-tooltip.hover.v-primary
                    :title="isDetailModel ? '整体模型' : '详细模型'"
                >
                    <feather-icon
                        :icon="isDetailModel ? 'MinimizeIcon' : 'MaximizeIcon'"
                    />
                </b-button>

                <!-- 详细模型 -->
                <template v-if="!!equip_item.detail_model_key">
                    <div class="w-100" style="height: 12rem">
                        <loading
                            v-show="loading_show"
                            :is-show="loading_show"
                            class="w-100 h-100"
                        />
                        <div
                            id="cardModel"
                            :style="'opacity:' + (loading_show ? '0' : '1')"
                            class="w-100 h-100"
                        ></div>
                    </div>

                    <hr class="my-0" />
                </template>

                <!-- 监控画面 -->
                <template v-if="equip_item.ip_address">
                    <div class="w-100" style="height: 12rem">
                        <video
                            v-show="!videoPlayerError"
                            id="info-card-video"
                            class="h-100 w-100"
                        ></video>
                        <div v-if="videoPlayerError" class="h-100 w-100">
                            <div
                                style="
                                    position: absolute;
                                    left: 50%;
                                    top: 50%;
                                    transform: translate(-50%, -50%);
                                "
                                class="text-center"
                            >
                                <h6>播放异常</h6>
                                <small>{{ videoPlayerError }}</small>
                            </div>
                        </div>
                        <b-button
                            v-b-tooltip.hover
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            class="btn-icon rounded-circle position-absolute"
                            style="right: 0.5rem; top: 1rem; z-index: 3"
                            variant="flat-primary"
                            @click="videoModelShow = true"
                        >
                            <feather-icon icon="MaximizeIcon" />
                        </b-button>
                    </div>

                    <hr class="my-0" />
                </template>

                <vue-perfect-scrollbar style="max-height: calc(100vh - 295px)">
                    <div v-if="params_group !== {}">
                        <app-collapse class="infoCollapse">
                            <!-- 状态参数 -->
                            <app-collapse-item
                                :isVisible="true"
                                title="状态参数"
                            >
                                <div
                                    v-show="
                                        params_group['status'] &&
                                        params_group['status'].length > 0
                                    "
                                >
                                    <div
                                        v-for="(v, i) in params_group['status']"
                                        v-if="v.param_name"
                                        :key="i"
                                        class="d-flex align-items-center justify-content-between mb-50 px-1"
                                    >
                                        <span>{{
                                            explode_params(v.param_name)
                                        }}</span>

                                        <span
                                            v-if="
                                                v.unit &&
                                                v.unit.indexOf('||') > -1
                                            "
                                        >
                                            <strong>{{
                                                v.unit.split("||")[
                                                    v.cur_value > 0 ? 1 : 0
                                                ]
                                            }}</strong>
                                        </span>
                                        <span v-else>
                                            <strong>{{
                                                v.cur_value !== null
                                                    ? v.cur_value
                                                    : "N/A"
                                            }}</strong>
                                            <small>{{ " " + v.unit }}</small>
                                        </span>
                                    </div>
                                </div>
                            </app-collapse-item>

                            <!-- 控制参数 -->
                            <app-collapse-item
                                :isVisible="true"
                                title="控制参数"
                            >
                                <div
                                    v-show="
                                        params_group['control'] &&
                                        params_group['control'].length > 0
                                    "
                                >
                                    <div
                                        v-for="(v, i) in params_group[
                                            'control'
                                        ]"
                                        v-if="v.param_name"
                                        :key="i"
                                        class="d-flex align-items-center justify-content-between mb-50 px-1"
                                    >
                                        <span>{{
                                            explode_params(v.param_name)
                                        }}</span>
                                        <span
                                            v-if="
                                                v.unit &&
                                                v.unit.indexOf('||') > 0
                                            "
                                        >
                                            <b-form-checkbox
                                                :disabled="!hasPermission"
                                                v-model="v.cur_value"
                                                class="m-0"
                                                inline
                                                switch
                                                @change="update_command(v)"
                                            >
                                                <strong>{{
                                                    v.unit.split("||")[
                                                        v.cur_value > 0 ? 1 : 0
                                                    ]
                                                }}</strong>
                                            </b-form-checkbox>
                                        </span>
                                        <span v-else class="d-flex">
                                            <b-form-input
                                                :disabled="!hasPermission"
                                                v-model="v.cur_value"
                                                class="text-right"
                                                inline
                                                number
                                                size="sm"
                                                style="width: 5rem"
                                                @change="update_command(v)"
                                            ></b-form-input>
                                            <small
                                                v-if="
                                                    v.unit &&
                                                    v.unit.indexOf('||') < 0
                                                "
                                                class="ml-50 mt-50"
                                                >{{ v.unit }}</small
                                            >
                                        </span>
                                    </div>
                                    <div
                                        class="mt-1 d-flex align-items-center justify-content-between"
                                    >
                                        <div>
                                            <b-form-checkbox
                                                :disabled="!hasPermission"
                                                v-model="isForceControl"
                                                switch
                                            >
                                                强制
                                            </b-form-checkbox>
                                        </div>
                                        <div>
                                            <b-button
                                                block
                                                variant="primary"
                                                :disabled="
                                                    !isForceControl &&
                                                    !hasPermission
                                                "
                                                @click="submit_command()"
                                                >设定
                                            </b-button>
                                        </div>
                                    </div>
                                </div>
                            </app-collapse-item>

                            <app-collapse-item
                                v-if="equip_item.error_handle"
                                :isVisible="true"
                                title="帮助"
                            >
                                <p
                                    class="px-2"
                                    v-html="equip_item.error_handle"
                                ></p>
                            </app-collapse-item>
                        </app-collapse>
                    </div>
                    <div v-else>
                        <p class="text-center">此设备暂无监控参数</p>
                    </div>
                </vue-perfect-scrollbar>
            </div>
        </template>

        <template v-else>
            <elevator-item-info-card
                v-if="equip_item.equip_name"
                :lift_name="lift_name"
                @video-play="videoModelShow = true"
            ></elevator-item-info-card>
        </template>

        <b-modal v-model="videoModelShow" centered hide-footer size="lg">
            <div class="position-relative" style="height: 36rem">
                <video
                    v-show="!videoModelPlayerError"
                    id="info-card-model-video"
                    class="h-100 w-100"
                ></video>
                <div v-if="videoModelPlayerError" class="h-100 w-100">
                    <div
                        style="
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                        "
                        class="text-center"
                    >
                        <h6>播放异常</h6>
                        <small>{{ videoModelPlayerError }}</small>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "@vue/composition-api";
import { setupViewer } from "@/libs/forge/app";
import Loading from "./ModelLoading";
import store from "@/store";
import { destroyViewer, getModelPath } from "@/libs/forge/utils/tools";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { showToast } from "@/libs/utils/showToast";
import flvjs from "flv.js";
import Ripple from "vue-ripple-directive";
import { routerParams } from "@/libs/utils/routerParams";
import ElevatorItemInfoCard from "@/views/front/jsf35/subsystems/components/intelligent-elevator/elevator_item_info_card";
import {
    easyMediaList,
    easyMediaAdd,
    easyMediaStart,
    easyMediaStop,
} from "@/api/jsf35";

export default {
    name: "ModelInfoCard",
    props: {
        time: Number | String,
    },
    directives: {
        Ripple,
    },
    components: {
        Loading,
        ElevatorItemInfoCard,
        VuePerfectScrollbar,
        AppCollapse,
        AppCollapseItem,
    },
    setup(props) {
        const hasPermission = ref(false);
        const system_key = routerParams("system_key");
        const viewer = ref(null);
        //两个视频播放实例
        const videoPlayer = ref(null);
        const videoPlayerError = ref(null);
        const videoModelShow = ref(false);
        const videoModelPlayer = ref(null);
        const videoModelPlayerError = ref(null);
        const isDetailModel = ref(false);
        const loading_show = ref(false);
        const check = ref(false);
        const getMes = computed(() => {
            return function (data, key) {
                return data[key];
            };
        });
        const show = ref(false);
        const activeIndex = ref(null);
        const inputVal = ref(null);
        const params_group = ref({});
        const updated_command_items = ref({});
        const fake = ref([
            {
                label: "流量",
                value: 10,
                edit: false,
            },
            {
                label: "风速",
                value: 20,
                edit: true,
            },
            {
                label: "转速",
                value: 30,
                edit: true,
            },
        ]);
        const shallShadowBottom = ref(false);
        const isForceControl = ref(false);

        const setVal = (i) => {
            show.value = !show.value;
            activeIndex.value = i;
            !show.value
                ? (fake.value[i].value = inputVal.value)
                : (inputVal.value = fake.value[i].value);
        };

        const equip_item = computed(() => {
            return Object.assign({}, store.state.jsf35.equip_selected);
        });

        const lift_name = computed(() => {
            let name = equip_item.value.equip_meter_code.split("_").pop();
            return name;
        });

        watch(equip_item, (equip_item) => {
            updated_command_items.value = {};
            isForceControl.value = false;
            if (equip_item.params && equip_item.params.length > 0) {
                params_group.value = {
                    status: equip_item.params.filter(
                        (param) => param.is_control !== 1
                    ),
                    control: equip_item.params.filter(
                        (param) => param.is_control === 1
                    ),
                };
            } else {
                params_group.value = {};
            }

            //若存在详细模型则显示详细模型
            if (equip_item.detail_model_key) {
                setTimeout(() => {
                    const box = document.getElementById("cardModel");
                    loading_show.value = true;
                    if (viewer.value) {
                        destroyViewer(viewer.value);
                    }
                    setupViewer(
                        box,
                        viewer,
                        getModelPath(72, equip_item.detail_model_key),
                        ["TurnTable", "MyAwesomeExtension", "SelectModel"],
                        {
                            ids: equip_item.dbId,
                        }
                    );
                }, 1000);
            }
            //若存在视频则播放视屏
            if (
                equip_item.ip_address &&
                system_key !== "intelligent-elevator"
            ) {
                videoPlay(equip_item.ip_address);
            }
        });

        //判断用户是否有权限
        const checkPermission = () => {
            const permission_type = "subsystems";
            const permission_page = "subsystems/" + routerParams("system_key");
            const permission_action = "edit";
            const permission =
                permission_type +
                "___" +
                permission_page +
                "___" +
                permission_action;
            const userPermission =
                store.state.jsf35.user_permissions ||
                JSON.parse(localStorage.getItem("jsf35UserPermission"));

            let _hasPermission = false;
            if (userPermission && userPermission.length > 0) {
                for (const item of userPermission) {
                    if (item === permission) {
                        _hasPermission = true;
                        break;
                    }
                }
            }
            hasPermission.value = _hasPermission;
            return _hasPermission;
        };

        watch(viewer, (newVal) => {
            if (newVal) {
                newVal.addEventListener(
                    Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
                    () => {
                        loading_show.value = false;
                        // if (props.equip_item.dbId) {
                        //   // newVal.select([props.equip_item.dbId], newVal.model, Autodesk.Viewing.SelectionType.REGULAR)
                        //   newVal.fitToView([props.equip_item.dbId]);
                        //   // newVal.isolate([props.equip_item.dbId])
                        // }

                        if (equip_item.model_rotate) {
                            const pos = equip_item.model_rotate
                                .split(",")
                                .map((item) => Number(item));
                            newVal.navigation.setPosition(
                                new THREE.Vector3(pos[0], pos[1], pos[2])
                            );
                        }

                        newVal.fitToView(equip_item.dbId || []);
                    }
                );
            }
        });

        watch(videoModelShow, () => {
            if (videoModelShow.value) {
                setTimeout(() => {
                    videoPlay(
                        store.state.jsf35.equip_selected.ip_address,
                        true
                    );
                }, 1000);
            } else {
                if (videoModelPlayer.value) {
                    videoModelPlayer.value.detachMediaElement();
                    videoModelPlayer.value.destroy();
                    videoModelPlayer.value = null;
                    videoModelPlayerError.value = null;
                }
            }
        });

        //初始化视频播放器，并开始播放
        const videoPlay = async (url, isModel = false) => {
            const videoId = equip_item.equip_id;
            if (flvjs.isSupported()) {
                if (videoPlayer.value && !isModel) {
                    videoPlayer.value.detachMediaElement();
                    videoPlayer.value.destroy();
                    videoPlayer.value = null;
                    videoPlayerError.value = null;
                }
                if (videoModelPlayer.value && isModel) {
                    videoModelPlayer.value.detachMediaElement();
                    videoModelPlayer.value.destroy();
                    videoModelPlayer.value = null;
                    videoModelPlayerError.value = null;
                }

                //若推流地址为空，则不播放
                if (!url) {
                    showToast(null, "播放异常", "此点位未配置摄像头推流地址");
                    return;
                }

                /*********************************************/
                //处理推流地址
                //数据库中的ip_address ws://localhost:8866/live?url=rtsp://admin:admin@172.16.2.67/media.amp?streamprofile=Profile1&audio=0
                if (url.indexOf("rtsp://") >= -1) {
                    url = "rtsp://" + url.split("rtsp://")[1];
                } else {
                    showToast(null, "视频推流地址异常", "请检查此点位推流地址");
                    return;
                }
                //获取EasyMediaServer的推流地址
                let ip_address_list = [];
                let params = { url: url };
                await easyMediaList()
                    .then(async (res) => {
                        if (res.data.data && res.data.data.length > 0) {
                            res.data.data.map((item) => {
                                ip_address_list.push(item.url);
                            });
                            //若url不存在easymedia列表中,则添加此地址
                            if (!ip_address_list.includes(url)) {
                                await easyMediaAdd({
                                    ...params,
                                    remark: "video_" + videoId,
                                }).catch((err) => {
                                    console.log(err);
                                    showToast(null, "添加推流地址失败", err);
                                    return false;
                                });
                            }
                            //开启推流
                            await easyMediaStart(params);
                            //30分钟后关闭推流
                            setTimeout(() => {
                                easyMediaStop(params);
                            }, 1000 * 60 * 30);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        showToast(null, "获取推流列表失败", err);
                        return false;
                    });

                const flv_url =
                    "/easy-media-streaming/live?url=" +
                    url +
                    "&&&autoClose=true";
                /*********************************************/

                const flvVideo = flvjs.createPlayer({
                    type: "flv",
                    url: flv_url,
                });
                let videoDom;
                if (isModel) {
                    videoDom = document.getElementById("info-card-model-video");
                } else {
                    videoDom = document.getElementById("info-card-video");
                }
                flvVideo.attachMediaElement(videoDom);
                flvVideo.load();
                let error_msg = "";
                flvVideo.on("error", function (error) {
                    error_msg = error;
                    showToast(null, "播放错误", error);
                    if (isModel) {
                        videoModelPlayerError.value =
                            "安防设备视频画面仅供内网访问";
                    } else {
                        videoPlayerError.value = "安防设备视频画面仅供内网访问";
                    }
                });
                //没有异常信息时播放
                if (!error_msg) {
                    if (isModel) {
                        videoModelPlayer.value = flvVideo;
                    } else {
                        videoPlayer.value = flvVideo;
                    }
                    flvVideo.play();
                }
            }
        };

        const explode_params = (param_name) => {
            //console.log('param_name', param_name)
            let str = param_name;
            if (param_name) {
                let name_arr = param_name.split("-");
                str = name_arr[name_arr.length - 1];
            }
            return str;
        };

        //发送请求修改控制参数
        const submit_command = () => {
            const list = Object.values(updated_command_items.value);
            store
                .dispatch("jsf35/submitCommand", { project_id: 72, list: list })
                .then((response) => {
                    showToast(null, "成功", "指令已下发成功！");
                })
                .catch((error) => {
                    showToast(error);
                });
        };
        //记录已更新的控制参数
        const update_command = (param_item) => {
            updated_command_items.value[param_item.tag_name] = {
                tag_name: param_item.tag_name,
                cur_value: param_item.cur_value,
            };
        };

        onMounted(() => {
            checkPermission();
            const box = document.getElementById("cardModel");
            if (equip_item.detail_model_key) {
                setTimeout(() => {
                    setupViewer(
                        box,
                        viewer,
                        equip_item.detail_model_key,
                        ["TurnTable", "MyAwesomeExtension", "SelectModel"],
                        {
                            ids: equip_item.dbId || [],
                        }
                    );
                }, 3000);
            }
        });

        return {
            system_key,
            lift_name,
            shallShadowBottom,
            getMes,
            loading_show,
            fake,
            show,
            activeIndex,
            inputVal,
            check,
            setVal,
            equip_item,
            explode_params,
            params_group,
            submit_command,
            update_command,
            videoPlayerError,
            videoModelPlayerError,
            videoModelShow,
            isDetailModel,
            isForceControl,
            hasPermission,
        };
    },
};
</script>

<style lang="scss" scoped>
/* ::v-deep .modal-lg {
    max-width: 60rem !important;
} */

.infoCollapse {
    .card {
        box-shadow: none;
    }
}
</style>
