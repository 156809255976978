<template>
  <div
    class="position-absolute"
    style="width: 100vw; height: 100vh; left: -2rem; top: calc(-2rem - 81px)"
  >
    <model-loading :is-show="loading_show" :size="0.06" class="w-100 h-100" />

    <div
      id="model"
      :style="'opacity:' + (loading_show ? '0' : '1')"
      class="w-100 h-100"
    >
      <b-card
        no-body
        class="position-fixed px-50 py-1"
        style="z-index: 2; top: 7rem; right: 2rem"
      >
        <b-button
          variant="flat-primary"
          class="btn-icon rounded-circle"
          @click="refreshModel"
          v-b-tooltip.hover.left.v-primary
          title="重置模型"
        >
          <b-icon-arrow-clockwise />
        </b-button>
        <b-button
          variant="flat-primary"
          class="btn-icon rounded-circle mt-25"
          @click="simulation_dialog = true"
          v-b-tooltip.hover.left.v-primary
          title="开启仿真"
        >
          <b-icon-bezier2 />
        </b-button>
        <b-button
          variant="flat-primary"
          class="btn-icon rounded-circle mt-25"
          @click="showMarksAction"
          v-b-tooltip.hover.left.v-primary
          :title="marksInfo.length ? '隐藏标签' : '显示标签'"
        >
          <feather-icon :icon="marksInfo.length ? 'EyeOffIcon' : 'EyeIcon'" />
        </b-button>

        <b-button
          v-if="system_key === 'electrical-substation'"
          variant="flat-primary"
          class="btn-icon rounded-circle mt-25"
          v-b-tooltip.hover.left.v-primary
          title="电力运维平台"
          @click="electrical_dialog = true"
        >
          <feather-icon icon="LinkIcon" />
        </b-button>

        <b-button
          v-if="hasPermission"
          v-show="equip_config_btn_show"
          id="btn"
          variant="flat-primary"
          class="btn-icon rounded-circle mt-25"
          @click="$bvModal.show('model-equip-config')"
          @mouseleave="equip_config_btn_show = false"
          v-b-tooltip.hover.left.v-primary
          title="组件配置"
        >
          <b-icon-pencil-square />
        </b-button>
      </b-card>
    </div>

    <!--	    <model-info-card-->
    <!--			    v-show="-->
    <!--                ![-->
    <!--                    'electrical-substation',-->
    <!--                    'energy-management',-->
    <!--                    'intelligent-lighting',-->
    <!--                ].includes(system_key) &&-->
    <!--                (equip_item.dbId.length || equip_item.params)-->
    <!--            "-->
    <!--			    style="-->
    <!--                position: absolute;-->
    <!--                top: 7rem;-->
    <!--                right: calc(2rem + 80px);-->
    <!--                z-index: 2;-->
    <!--            "-->
    <!--			    time="2000"-->
    <!--			    @switchModel="switchModel"-->
    <!--	    />-->

    <b-sidebar
      id="model-info-card-sidebar"
      right
      shadow
      lazy
      bg-variant="white"
      v-model="modelInfoCardShow"
    >
      <model-info-card @switchModel="switchModel" />
    </b-sidebar>

    <b-sidebar
      id="electrical-substation-iframe"
      right
      shadow
      lazy
      bg-variant="white"
      width="calc(100vw - 80px)"
      v-model="electrical_dialog"
    >
      <b-embed
        :src="'http://60.205.59.109:8090/#/login'"
        allow-cross-origin
        allowfullscreen
        lazy
        aspect="4by3"
        type="iframe"
        style="width: 100%; height: 100%"
      ></b-embed>
    </b-sidebar>

    <!--    鼠标hover浮层-->
    <div
      v-for="(item, i) in marksInfo"
      :key="i"
      class="c_app"
      style="position: fixed; z-index: 2; display: block"
    >
      <div
        v-if="!(Object.keys(item).length < 8 && modelIsDetail)"
        :class="
          item.equip_status === -1
            ? 'c_box_alarm'
            : item.equip_status === 1
            ? 'c_box_run'
            : ''
        "
        class="c_box"
      >
        <div v-show="item.is_show || item.is_hover" class="c_content">
          <div
            v-show="!modelIsDetail"
            class="c_title"
            @click="showInfoCard(item.code)"
          >
            {{ item.name }}
          </div>

          <template v-if="system_key !== 'intelligent-elevator'">
            <div v-show="!modelIsDetail && item.code" class="c_subtitle">
              <span>[ </span>
              <!--              <span v-if="system_key === 'intelligent-security'">{{-->
              <!--                item.code-->
              <!--              }}</span>-->
              <span>ID #{{ item.code || "此点位未绑定" }}</span>
              <span> ]</span>
            </div>
            <div
              v-for="(value, key) in item"
              v-if="
                key &&
                ![
                  'name',
                  'code',
                  'detail_model_key',
                  'equip_status',
                  'error_params',
                  'is_show',
                  'is_hover',
                ].includes(key)
              "
              class="c_mes"
            >
              <!--若为消防系统则单独显示报警信息-->
              <template v-if="system_key === 'intelligent-fire-fighting'">
                <small
                  :class="
                    item.error_params.includes(key)
                      ? 'text-danger text-bold'
                      : ''
                  "
                  v-html="value"
                >
                </small>
              </template>
              <template v-else>
                <small
                  :class="
                    item.error_params.includes(key)
                      ? 'text-danger text-bold'
                      : ''
                  "
                  class="text-uppercase"
                  >{{ explode_params(key) }}</small
                >
                <small
                  :class="
                    item.error_params.includes(key)
                      ? 'text-danger text-bold'
                      : ''
                  "
                  v-if="value && value.length"
                  class="ml-3"
                >
                  {{ value.split(" ")[0] }}
                  <small
                    v-show="
                      value.split(' ')[1] &&
                      !(value.split(' ')[1].indexOf('||') > -1)
                    "
                  >
                    {{ value.split(" ")[1] }}
                  </small>
                </small>
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>
    <model-config @updateModel="changeModel"></model-config>

    <div
      v-show="simulation_dialog"
      class="position-absolute simulation-dialog-in"
      style="
        top: 0;
        right: 80px;
        width: calc(100vw - 80px);
        height: 100vh;
        background: #f8f8f8;
        z-index: 100;
      "
    >
      <div class="position-absolute" style="z-index: 2; top: 1rem; right: 1rem">
        <b-button
          class="btn-icon ml-1"
          variant="primary"
          size="sm"
          @click="simulation_dialog = false"
        >
          <feather-icon icon="XIcon" />
        </b-button>
      </div>

      <b-embed
        :src="'https://building-simulation.mtec.cc/'"
        allow-cross-origin
        allowfullscreen
        lazy
        aspect="4by3"
        type="iframe"
        style="width: 100%; height: 100%"
      ></b-embed>
    </div>
  </div>
</template>

<script>
import { showToast } from "@/libs/utils/showToast";

import store from "@/store";
import ModelLoading from "./ModelLoading";
import ModelConfig from "./ModelConfig";
import { isObjectChanged } from "@/libs/utils/isObjectChanged";
import ModelInfoCard from "@/views/front/jsf35/subsystems/layout/components/ModelInfoCard";
import {
  computed,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  toRefs,
  watch,
} from "@vue/composition-api";
import { routerParams } from "@/libs/utils/routerParams";
import { setupViewer } from "@/libs/forge/app";
import {
  destroyViewer,
  getChildren,
  getDetailDbId,
  getFragId,
  getModelPath,
  getModifiedWorldBoundingBox,
  getNodeName,
  getNodeParentId,
  isolate,
  isolateFull,
  resetIsolate,
  updateDiv,
} from "@/libs/forge/utils/tools";

import * as MyAwesomeExtension from "@/libs/forge/extensions/backgroud";
import * as SelectModel from "@/libs/forge/extensions/selectModel";
import * as TurnTable from "@/libs/forge/extensions/camareRotate";

import { modelLift, modelObj } from "@/libs/forge/utils/data";

let anima;
// 用于寻找父节点
let flag = false;
let eleFlag = false;

const ml = modelLift();

let modelFlag = false;

let positionSet = false;

const {
  color,
  lightColorOn,
  lightColorOff,
  shotLift,
  rotateObj,
  rotateArray,
  floorPos,
  liftSpeed,
} = modelObj;

export default {
  name: "modelDiv",
  components: {
    ModelConfig,
    ModelInfoCard,
    ModelLoading,
  },
  props: ["main_model", "detail_model"],
  setup(props) {
    const hasPermission = ref(false);
    const simulation_dialog = ref(false);
    const electrical_dialog = ref(false);
    const currentModel = ref(props.main_model);
    // const modalShow = ref(false);
    const loading_show = ref(true);
    const project_id = routerParams("project_id");
    const system_key = routerParams("system_key");
    const viewer = ref(null);
    const equip_item = reactive({
      dbId: [],
      params: null,
      model_dbids: [],
      model_rotate: "",
    });
    const marksInfo = ref([]);
    const marks = ref([]);
    const modelPath = computed(() => {
      return getModelPath(project_id, currentModel.value?.path);
    });

    //判断用户是否有权限
    const checkPermission = () => {
      const permission_type = "subsystems";
      const permission_page = "subsystems/" + routerParams("system_key");
      const permission_action = "edit";
      const permission =
        permission_type + "___" + permission_page + "___" + permission_action;
      const userPermission =
        store.state.jsf35.user_permissions ||
        JSON.parse(localStorage.getItem("jsf35UserPermission"));

      let _hasPermission = false;
      if (userPermission && userPermission.length > 0) {
        for (const item of userPermission) {
          if (item === permission) {
            _hasPermission = true;
            break;
          }
        }
      }
      hasPermission.value = _hasPermission;
      return _hasPermission;
    };

    //获取所有节点名称列表
    const nodeNameList = (viewer = viewer.value) => {
      const dbIds = getDetailDbId(viewer, ["equipment_"]);
      const allDbIds = dbIds["equipment_"] || [];
      // console.log('allDbIds',allDbIds)
      let nodeList = {};
      allDbIds.map((dbId) => {
        const nodeName = getNodeName(viewer, dbId) || "";
        if (nodeName.startsWith("equipment_")) {
          nodeList[nodeName] = dbId;
        }
      });
      store.commit("jsf35/setDbIdList", Object.values(nodeList));
      // console.log('nodeList',nodeList)
      return nodeList;
    };

    const modelIsDetail = computed(() => {
      return currentModel.value?.isDetail;
    });

    // 配置按钮
    const config = reactive({
      equip_config_btn_show: false,
      modelKey: computed(() => {
        return currentModel.value?.path;
      }),
      dbIdList: [],
      dbId: [],
    });

    // 悬浮ID
    const hoverDbId = ref(null);

    // 设备列表
    const equipmentList = ref({});

    // 是否显示信息窗口
    const modelInfoCardShow = ref(false);

    // 移动参数
    const move = reactive({
      flag: true,
      frags: [],
      speed: -100,
      count: 0,
    });

    const lastLift = reactive({
      lift: "",
      shaft: "",
      count: 0,
    });

    const lastData = ref(null);

    const ROTATE = reactive({
      flag: true,
      frags: [],
      right: [],
      center: [],
      axis: [],
    });

    // const flag = ref(false)

    const parentId = ref(null);

    const systemShowIds = ref([]);

    const rotate = ref(null);

    const moves = ref([]);

    const stopFragId = ref([]);

    const explode_params = (param_name) => {
      //console.log('param_name', param_name)
      let name_arr = param_name.split("-");
      return name_arr[name_arr.length - 1];
    };

    //跳转到设备详细模型
    const showInfoCard = () => {
      const equip_hovered = Object.assign({}, store.state.jsf35.equip_hovered);
      store.commit("jsf35/setEquipSelected", equip_hovered);
    };

    const switchModel = () => {
      const detail_model_key =
        store.state.jsf35.equip_selected?.detail_model_key;
      if (currentModel.value?.isDetail) {
        currentModel.value = props.main_model;
      } else if (detail_model_key) {
        currentModel.value = { path: detail_model_key, isDetail: true };
      } else {
        showToast("", "提示", "模型异常！");
      }
    };

    //初始化页面模型
    const initModel = () => {
      if (modelPath.value) {
        loading_show.value = true;
        if (viewer.value) {
          // objResizeObserver.disconnect();
          destroyViewer(viewer.value);
          equip_item.dbId = [];
          marks.value = [];
          marksInfo.value = [];
        }
        store.commit("jsf35/setEquipSelected", null);
        const modelBox = document.getElementById("model");
        modelBox.oncontextmenu = (e) => {
          e.preventDefault();
          if (e.button === 2) {
            //若未选中组件，则清空vuex的数据
            if (!config.dbId.length) {
              store.commit("jsf35/setEquipSelected", null);
            }
            config.equip_config_btn_show = true;
            const btn = document.getElementById("btn");
            btn.style.left = e.clientX + "px";
            btn.style.top = e.clientY - 62.4 + "px";
          }
        };
        setupViewer(
          modelBox,
          viewer,
          modelPath.value,
          ["TurnTable", "MyAwesomeExtension", "SelectModel"],
          {},
          currentModel.value?.transparent
        );
        // objResizeObserver.observe(modelBox);
      }
    };

    //父级传入的模型变化时，响应变化
    watch(
      () => props.detail_model,
      (model) => {
        if (model.path) {
          currentModel.value = model;
        } else {
          currentModel.value = props.main_model;
        }
      }
    );

    //监听设备选中变化
    watch(
      () => store.state.jsf35.equip_selected,
      (equip_selected) => {
        console.log("info-card:equip_selected", equip_selected);
        equip_item.model_key = equip_selected?.model_key;
        equip_item.detail_model_key = equip_selected?.detail_model_key;
        equip_item.params = equip_selected?.params;
        equip_item.model_dbids = equip_selected?.model_dbids;
        equip_item.equip_name = equip_selected?.equip_name;
        equip_item.model_rotate = equip_selected?.model_rotate;

        if (
          equip_selected?.equip_key &&
          system_key !== "intelligent-elevator"
        ) {
          let dbId = getDetailDbId(viewer.value, [equip_selected.equip_key]);
          let dbIds = dbId[equip_selected.equip_key];
          console.log("dbIds", dbIds);
          if (dbIds && dbIds.length > 0) {
            isolate(viewer.value, dbIds);
          }
        }

        //判断是否显示信息窗口
        const model_info_card_sidebar_show = !!(
          ![
            "electrical-substation",
            "energy-management",
            "intelligent-lighting",
          ].includes(system_key) &&
          (equip_item.dbId.length || equip_item.params)
        );
        if (model_info_card_sidebar_show) {
          modelInfoCardShow.value = true;
        }
      }
    );

    watch(
      [() => currentModel.value?.path, () => currentModel.value?.dbIds],
      ([m, d]) => {
        if (currentModel.value) {
          store.commit("jsf35/setModelSelected", currentModel.value?.path);
          changeModel({ modelKey: currentModel.value?.path });
        }
      }
    );

    watch(
      () => currentModel.value?.isDetail,
      (v) => {
        if (v === false) {
          equip_item.params = null;
          equip_item.model_dbids = [];
          store.commit("jsf35/setEquipSelected", null);
        }
      }
    );

    watch(
      () => parentId.value,
      (newVal) => {
        if (newVal) {
          //console.log(newVal);
          viewer.value.select(newVal);
          parentId.value = null;
        }
      }
    );

    watch(
      () => currentModel.value?.mode,
      (newVal) => {
        currentModel.value?.mode && viewer.value.setLightPreset(newVal);
      }
    );

    watch(viewer, (newVal) => {
      if (newVal) {
        newVal.impl.setGroundShadowAlpha(0);
        //设备选中
        newVal.addEventListener(
          Autodesk.Viewing.SELECTION_CHANGED_EVENT,
          (e) => {
            const { dbIdArray, fragIdsArray } = e;
            // console.log('e', e)
            if (flag) {
              flag = false;
              return;
            }

            if (dbIdArray.length === 1) {
              parentId.value = getNodeParentId(newVal, dbIdArray[0]);
              let equip_key = getNodeName(newVal, parentId.value);
              if (equip_key) {
                store.state.jsf35.equip_list.map((item) => {
                  if (item.equip_key === equip_key) {
                    store.commit("jsf35/setEquipSelected", item);
                  }
                });
                store.commit("jsf35/setEquipKeySelected", equip_key);
              }

              flag = true;
            }

            store.commit("jsf35/setDbIdSelected", dbIdArray);

            config.dbId = dbIdArray;
            dbIdArray.length || (equip_item.dbId = []);

            if (currentModel.value.isDetail) {
              if (systemShowIds.value) {
                isolate(newVal, systemShowIds.value);
              } else {
                resetIsolate(newVal);
              }
            }
            // equip_item.params = null
            if (fragIdsArray.length) {
              // if (currentModel.value?.path.startsWith("surveillance_system")) {
              //   modalShow.value = true;
              // }
              getChildren(newVal, parentId.value, true).then((res) => {
                equip_item.dbId = res;
              });
              config.dbId = dbIdArray;
              // console.log('dbIdArray',dbIdArray)
              currentModel.value.isDetail && isolate(newVal, dbIdArray);
            }
          }
        );
        //鼠标Hover
        newVal.addEventListener(
          Autodesk.Viewing.OBJECT_UNDER_MOUSE_CHANGED,
          (e) => {
            const { dbId } = e;
            hoverDbId.value = dbId;
            const parentId = getNodeParentId(newVal, dbId);
            let equip_key = getNodeName(newVal, parentId);
            if (
              equip_key &&
              equip_key.startsWith("equipment_") &&
              !currentModel.value?.isDetail
            ) {
              store.state.jsf35.equip_list.map((item) => {
                if (item.equip_key === equip_key) {
                  store.commit("jsf35/setEquipHovered", item);
                }
              });
            }
          }
        );
        //视角变化
        newVal.addEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, (e) => {
          rotate.value = e.camera.position;
          // console.log('rotate-position',e.camera.position)
          // console.log('rotate-far',e.camera.far)
          updateDiv(marks.value, newVal, "c_app", "model");
        });

        //模型创建完成
        newVal.addEventListener(
          Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT,
          (e) => {
            // 楼层隔离，系统不隔离
            currentModel.value?.mode &&
              newVal.setLightPreset(currentModel.value?.mode);
            // if (currentModel.value?.showDbIds) {
            //   showDbIds.value = Object.values(getDetailDbId(newVal, currentModel.value?.showDbIds)).flat()
            //   newVal.loadModel(currentModel.value?.path, showDbIds.value, ()=>{}, ()=>{});
            // }

            if (currentModel.value?.isDetail) {
              const dbIds = getDetailDbId(newVal, ["equipment_"]);
              //console.log(dbIds);
              systemShowIds.value = dbIds["equipment_"];
              systemShowIds.value &&
                systemShowIds.value.forEach((item) => {
                  getChildren(newVal, item, true).then((res) => {
                    systemShowIds.value.push(res);
                  });
                });
              if (systemShowIds.value && systemShowIds.value.length) {
                isolate(newVal, systemShowIds.value);
              }
            } else {
              if (currentModel.value?.dbIds) {
                let arr = [];

                if (Array.isArray(currentModel.value?.dbIds)) {
                  arr = currentModel.value?.dbIds;
                } else {
                  arr = [currentModel.value?.dbIds];
                }
                //console.log(arr);
                const data = getDetailDbId(newVal, [
                  ...arr,
                  currentModel.value?.colorDbIds,
                ]);
                //console.log(data);
                const val = arr
                  .reduce((acc, cur) => {
                    acc.push(data[cur]);
                    return acc;
                  }, [])
                  .flat();

                val.forEach((item) => {
                  getChildren(newVal, item, true).then((res) => {
                    val.push(res);
                  });
                });

                !currentModel.value.isDetail &&
                  isolate(
                    newVal,
                    val.flat(),
                    data[currentModel.value?.colorDbIds] || []
                  );

                const show = Object.values(
                  getDetailDbId(newVal, currentModel.value?.showDbIds)
                ).flat();

                if (show.length) {
                  isolateFull(newVal, newVal.model, show);
                  isolate(newVal, val.flat());
                }
              }
            }
            // 调整角度
            if (currentModel.value.rotate || equip_item.model_rotate) {
              let pos = [];
              if (currentModel.value.rotate) {
                pos = currentModel.value.rotate
                  .split(",")
                  .map((item) => Number(item));
              } else {
                pos = equip_item.model_rotate
                  .split(",")
                  .map((item) => Number(item));
              }
              newVal.navigation.setPosition(
                new THREE.Vector3(pos[0], pos[1], pos[2])
              );
              if (currentModel.value?.isDetail) {
              }
            }
          }
        );

        newVal.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, (e) => {
          modelFlag = true;
          loading_show.value = false;
          // if (props.dbIds.length === 1) {
          newVal.fitToView();
          newVal.setQualityLevel(true, true);
          newVal.setProgressiveRendering(false);

          showAllMarks(newVal);

          //若设置激活状态的设备，则改变颜色并显示标签
          if (currentModel.value?.activeDbIds) {
            console.log("activeDbIds", currentModel.value?.activeDbIds);
            const group_keys = currentModel.value?.activeDbIds;
            const arr = getDetailDbId(newVal, group_keys);
            console.log("arr", arr);
            for (let i of group_keys) {
              if (arr[i]) {
                for (let j of arr[i]) {
                  getChildren(newVal, j).then((res) => {
                    newVal.setThemingColor(
                      res,
                      new THREE.Vector4(255 / 255, 0 / 255, 0 / 255, 1)
                    );
                  });
                  marksShow(newVal, j);
                }
              }
            }
          }

          if (
            currentModel.value?.moveDbIds &&
            currentModel.value.moveDbIds.length
          ) {
            if (currentModel.value?.moveDbIds.length > 1) {
              currentModel.value?.moveDbIds.forEach((item) => {
                const obj = {
                  flag: true,
                  secret: shotLift.includes(item),
                  name: item,
                  dbId: [],
                  frags: [],
                  speed: -100,
                  count: 0,
                  total: 0,
                  anima: null,
                };
                moves.value.push(obj);
              });

              const data = getDetailDbId(newVal, [
                ...currentModel.value?.moveDbIds,
                currentModel.value?.moveTrack,
              ]);

              for (let i of moves.value) {
                i.dbId = data[i.name];
              }

              const getData = async () => {
                for (let i of moves.value) {
                  for (let j of i.dbId) {
                    const res = await getChildren(newVal, j);
                    const fragId = await getFragId(newVal, res);
                    i.frags.push(fragId);
                  }
                }
                renders();
              };

              getData();

              eleFlag = false;
            }

            if (currentModel.value?.moveDbIds.length === 1) {
              lastLift.lift = currentModel.value?.moveDbIds;
              lastLift.shaft = currentModel.value?.moveTrack;

              const data = getDetailDbId(newVal, [
                ...currentModel.value?.moveDbIds,
                currentModel.value?.moveTrack,
              ]);

              currentModel.value?.moveTrack &&
                currentModel.value?.moveTrack.forEach((item) => {
                  data[item].forEach((item1) => {
                    getChildren(newVal, item1).then((res) => {
                      newVal.setThemingColor(res, color);
                    });
                  });
                });

              currentModel.value?.moveDbIds.forEach((item) => {
                data[item].forEach((item1) => {
                  getChildren(newVal, item1).then((e) => {
                    getFragId(newVal, e).then((res) => {
                      move.frags.push(res);
                    });
                  });
                });
              });
              render();

              eleFlag = true;
            }
          }

          if (equip_item.equip_name) {
            rotateArray.forEach((item) => {
              if (equip_item.equip_name.includes(item)) {
                rotateObj[item].forEach((obj) => {
                  getFragId(newVal, obj.dbId).then((res) => {
                    ROTATE.frags.push(res);
                    ROTATE.center.push(
                      getModifiedWorldBoundingBox(newVal, [res]).center()
                    );
                    ROTATE.right.push(obj.right);
                    ROTATE.axis.push(obj.axis);
                  });
                });
                renderRT();
              }
            });
          }

          if (currentModel.value?.light_switch) {
            //console.log("灯光数据", currentModel.value?.light_switch);

            let groups = {};
            Object.values(currentModel.value?.light_switch)
              .flat()
              .map((item) => {
                if (item.light_list) {
                  for (let i in item.light_list) {
                    if (!groups[i]) {
                      groups[i] = false;
                    }
                    if (item.light_status) {
                      groups[i] = true;
                    }
                  }
                }
              });

            const group_keys = Object.keys(groups);
            const arr = getDetailDbId(newVal, group_keys);
            for (let i of group_keys) {
              if (arr[i]) {
                if (!!groups[i]) {
                  for (let j of arr[i]) {
                    getChildren(newVal, j).then((res) => {
                      newVal.setThemingColor(res, lightColorOn);
                    });
                  }
                } else {
                  for (let k of arr[i]) {
                    getChildren(newVal, k).then((res) => {
                      newVal.setThemingColor(res, lightColorOff);
                    });
                  }
                }
              }
            }
          }
        });
      }
    });

    watch(marks, (val) => {
      updateDiv(val, viewer.value, "c_app", "model");
    });

    watch(
      () => currentModel.value?.moveDbIds,
      (newVal) => {
        // if (eleFlag && viewer.value) {
        if (viewer.value && newVal) {
          // cancelAnimationFrame(anima)
          if (currentModel.value?.moveDbIds.length === 1) {
            if (lastLift.lift) {
              const data = getDetailDbId(viewer.value, [
                ...lastLift.lift,
                ...lastLift.shaft,
              ]);
              lastLift.shaft.forEach((item) => {
                data[item].forEach((item1) => {
                  getChildren(viewer.value, item1).then((res) => {
                    viewer.value.setThemingColor(
                      res,
                      new THREE.Vector4(46 / 255, 204 / 255, 113 / 255, 1)
                    );
                  });
                });
              });
              lastLift.lift = currentModel.value?.moveDbIds;
              lastLift.shaft = currentModel.value?.moveTrack;
              // lastLift.count = 0
            } else {
              lastLift.lift = currentModel.value?.moveDbIds;
              lastLift.shaft = currentModel.value?.moveTrack;
            }

            const data = getDetailDbId(viewer.value, [
              ...currentModel.value?.moveDbIds,
              currentModel.value?.moveTrack,
            ]);
            currentModel.value?.moveTrack &&
              currentModel.value?.moveTrack.forEach((item) => {
                data[item].forEach((item1) => {
                  getChildren(viewer.value, item1).then((res) => {
                    viewer.value.setThemingColor(res, color);
                  });
                });
              });

            // currentModel.value?.moveDbIds.forEach(item => {
            //   data[item].forEach(item1 => {
            //     viewer.value.model.getData().instanceTree.enumNodeChildren(item1, (e) => {
            //       getFragId(viewer.value, e).then(res => {
            //         move.frags.includes(res) || move.frags.push(res)
            //       })
            //     }, false)
            //   })
            // })
            // move.count = 0
            // move.speed = -100
            // render()
          }
        }
      }
    );

    // 鼠标hover时显示模型的信息
    watch(hoverDbId, (dbId, oldDbid) => {
      marksShow(viewer.value, dbId, true);

      // marksShow(viewer.value, dbId, true)
      // if (dbId && (dbId !== -1)) {
      // console.log('nodeName_' + dbId, nodeName)

      // const parentId = getNodeParentId(viewer.value, dbId);
      // let nodeName = getNodeName(viewer.value, parentId);
      // const parentId_old = getNodeParentId(viewer.value, oldDbid);
      // let nodeName_old = getNodeName(viewer.value, parentId_old);
      // if (nodeName && nodeName.startsWith('equipment_') && (nodeName !== nodeName_old)) {
      //   console.log('nodeName_' + dbId, nodeName)
      //   marksShow(viewer.value, dbId, true)
      // }
      // }
    });

    const showAllMarks = (viewer) => {
      if (viewer.model) {
        const nodeList = nodeNameList(viewer);
        const allDbIds = Object.values(nodeList);
        // console.log("nodeList", nodeList);
        allDbIds.map((dbId) => {
          marksShow(viewer, dbId, false);
        });
        setTimeout(() => {
          updateDiv(marks.value, viewer, "c_app", "model");
        }, 500);
      }
    };

    //显示所有标签
    const showMarksAction = () => {
      if (marksInfo.value.length > 0) {
        marksInfo.value = [];
        marks.value = [];
      } else {
        showAllMarks(viewer.value);
      }
    };

    //显示标签
    const marksShow = (viewer, dbId, isHover = false) => {
      if (dbId !== -1) {
        dbId = isHover ? dbId : dbId + 1;
        const parentId = getNodeParentId(viewer, dbId);
        const isDetail = currentModel.value?.isDetail;
        let nodeName = getNodeName(viewer, parentId);
        nodeName &&
          nodeName.startsWith("equipment_") &&
          getFragId(viewer, dbId).then((res) => {
            let index = -1;
            let node_is_exist = false;
            if (isHover) {
              marks.value.flat().map((mark, markIndex) => {
                if (marksInfo.value[markIndex].is_hover) {
                  marksInfo.value[markIndex].is_hover = false;
                  if (
                    markIndex === marks.value.length - 1 &&
                    !marksInfo.value[markIndex].is_show
                  ) {
                    marksInfo.value.splice(markIndex, 1);
                    marks.value.splice(markIndex, 1);
                  }
                }
              });
            }

            marks.value.flat().map((mark, markIndex) => {
              if (mark === res && isHover) {
                index = markIndex;
                marksInfo.value[index].is_hover = true;
              }
              if (marksInfo.value[markIndex].equip_key === nodeName) {
                node_is_exist = true;
              }
            });

            //若标签不存在，则添加
            if (index === -1 && !node_is_exist) {
              let equip_data = {};
              if (isDetail) {
                equip_data = Object.assign(
                  {},
                  store.state.jsf35.equip_selected
                );
                equip_data.params = equip_data.params || [];
                const node_param_key = nodeName.replace("equipment_", "");
                let params = [];
                if (node_param_key !== "") {
                  equip_data.params.map((item) => {
                    if (item.param_code.startsWith(node_param_key)) {
                      params.push(item);
                    }
                  });
                } else {
                  params = equip_data.params;
                  const nodeNameArr = Object.keys(nodeNameList(viewer));
                  equip_data.params.map((item, index) => {
                    nodeNameArr.map((node) => {
                      if (item.param_code.startsWith(node)) {
                        params.splice(index, 1);
                      }
                    });
                  });
                }
                equip_data.params = params;
              } else {
                equip_data = equipmentList.value[nodeName];
              }
              if (equip_data && Object.keys(equip_data)) {
                //用于mark标记点位运行状态
                //1: 运行正常、无报警 , 0: 运行异常、无报警 , -1:有报警
                let equip_status = 0;
                const {
                  equip_key,
                  equip_meter_code,
                  equip_name,
                  detail_model_key,
                  location,
                  params,
                } = equip_data;
                let obj = {};
                let error_params = [];
                if (params) {
                  obj = params.reduce((acc, cur) => {
                    if (cur.is_control !== 1) {
                      //若有运行参数异常，设备状态为0
                      if (
                        cur.param_name &&
                        cur.param_name.indexOf("运行状态") > -1 &&
                        cur.cur_value > 0 &&
                        equip_status !== -1
                      ) {
                        equip_status = 1;
                      }
                      //若有故障参数异常，设备状态为-1
                      if (
                        cur.param_name &&
                        cur.param_name.indexOf("报警") > -1 &&
                        cur.cur_value > 0 &&
                        equip_status > -1
                      ) {
                        equip_status = -1;
                        //若参数状态异常，则保存进异常参数数组
                        error_params.push(cur.param_name);
                      }
                      //应急页面报警
                      if (
                        system_key === "emergency-evacuation" &&
                        cur.cur_value !== "正常"
                      ) {
                        equip_status = -1;
                        //若参数状态异常，则保存进异常参数数组
                        error_params.push(cur.param_name);
                      }
                      //消防页面报警展示
                      if (
                        system_key === "intelligent-fire-fighting" &&
                        cur.cur_value
                      ) {
                        equip_status = 0;
                        //排除无效应答、非火警、清除、消除、解除、系统正常
                        if (
                          cur.cur_value.indexOf("无效应答") === -1 &&
                          cur.cur_value.indexOf("非火警") === -1 &&
                          cur.cur_value.indexOf("清除") === -1 &&
                          cur.cur_value.indexOf("消除") === -1 &&
                          cur.cur_value.indexOf("解除") === -1 &&
                          cur.cur_value.indexOf("系统正常") === -1
                        ) {
                          equip_status = -1;
                          //若参数状态异常，则保存进异常参数数组
                          error_params.push(cur.param_name);
                        }
                      }

                      const value =
                        cur.cur_value === 65535 || cur.cur_value === null
                          ? "N/A"
                          : cur.cur_value;
                      if (cur.unit && cur.unit.indexOf("||") > 0) {
                        acc[cur.param_name] =
                          cur.unit.split("||")[cur.cur_value > 0 ? 1 : 0];
                      } else {
                        acc[cur.param_name] =
                          value + (cur.unit ? " " + cur.unit : "");
                      }
                    }
                    return acc;
                  }, {});
                }
                if (error_params.length > 0) {
                  console.log("异常", error_params);
                }

                obj.name = equip_name;
                obj.code = equip_meter_code || location;
                obj.detail_model_key = detail_model_key;
                obj.equip_status = equip_status;
                obj.error_params = error_params;
                obj.is_show = false;
                obj.is_hover = false;
                //hover时显示
                if (
                  isHover ||
                  isDetail ||
                  equip_status === -1 ||
                  equip_status === 1
                ) {
                  if (isHover) {
                    obj.is_hover = true;
                  }
                  if (isDetail) {
                    obj.is_show = true;
                  }
                  // if (!isDetail || equip_status === 0) {
                  //   obj.is_show = false;
                  // }
                  marksInfo.value.push(obj);
                  marks.value.push([res]);
                }
              }
            }
          });
      } else {
        console.log("dbId is -1");
      }
    };

    //灯光变化
    watch(
      () => currentModel.value?.light_switch,
      (light_switch) => {
        if (light_switch) {
          let groups = {};
          Object.values(light_switch)
            .flat()
            .map((item) => {
              if (item.light_list) {
                for (let i in item.light_list) {
                  if (!groups[i]) {
                    groups[i] = false;
                  }
                  if (item.light_status) {
                    groups[i] = true;
                  }
                }
              }
            });
          const group_keys = Object.keys(groups);

          if (modelFlag) {
            const arr = getDetailDbId(viewer.value, group_keys);
            for (let i of group_keys) {
              if (arr[i]) {
                if (!!groups[i]) {
                  for (let j of arr[i]) {
                    getChildren(viewer.value, j).then((res) => {
                      viewer.value.setThemingColor(res, lightColorOn);
                    });
                  }
                } else {
                  for (let k of arr[i]) {
                    getChildren(viewer.value, k).then((res) => {
                      viewer.value.setThemingColor(res, lightColorOff);
                    });
                  }
                }
              }
            }
          }
        }
      }
    );

    // const objResizeObserver = setResize("adsk-viewing-viewer", width, height);

    //切换模型
    const changeModel = (model) => {
      cancelAnimationFrame(anima);
      move.count = 0;
      move.speed = -100;
      move.frags = [];

      moves.value = [];

      ROTATE.center = [];
      ROTATE.frags = [];
      ROTATE.axis = [];
      ROTATE.right = [];

      modelFlag = false;
      positionSet = false;

      //console.log("changeModel", model, currentModel.value);
      const modelKey = model.modelKey || currentModel.value?.path;
      if (modelKey) {
        loading_show.value = true;
        if (viewer.value) {
          // objResizeObserver.disconnect();
          destroyViewer(viewer.value);
        }
        if (!(model?.isDetail || currentModel.value?.isDetail)) {
          store.commit("jsf35/setEquipSelected", null);
        }
        const modelBox = document.getElementById("model");
        setupViewer(
          modelBox,
          viewer,
          getModelPath(project_id, modelKey),
          ["TurnTable", "MyAwesomeExtension", "SelectModel"],
          {
            ids: [],
          }
        );
        // objResizeObserver.observe(modelBox);
        equip_item.dbId = [];
        marks.value = [];
        marksInfo.value = [];
      } else {
        showToast(null, "异常", "模型Key为空");
      }
    };

    const render = () => {
      if (move.flag) {
        move.frags.forEach((fragId) => {
          let fragProxy = viewer.value.impl.getFragmentProxy(
            viewer.value.model,
            fragId
          );
          fragProxy.getAnimTransform();
          fragProxy.position.z += move.speed;
          move.count += move.speed;
          fragProxy.updateAnimTransform();
        });
        anima = requestAnimationFrame(render);
        viewer.value.impl.sceneUpdated(true);
      }
    };

    const renders = () => {
      //console.log("设置电梯初始位置");
      if (modelFlag && currentModel.value?.moveData) {
        lastData.value = currentModel.value?.moveData;
        moves.value.forEach((item) => {
          const data = currentModel.value?.moveData[ml[item.name]];
          if (item.flag && data) {
            item.frags.forEach((fragId) => {
              let fragProxy = viewer.value.impl.getFragmentProxy(
                viewer.value.model,
                fragId
              );
              fragProxy.getAnimTransform();
              // //console.log(floorPos[data.ActualPosition], item.name, shotLift.includes(item.name), data.ActualPosition)
              fragProxy.position.z = shotLift.includes(item.name)
                ? floorPos[data.ActualPosition] + 4050
                : floorPos[data.ActualPosition];
              fragProxy.updateAnimTransform();
              positionSet = true;
            });
          }
        });
        viewer.value.impl.sceneUpdated(true);
      } else {
        moves.value.forEach((item) => {
          item.count = 0;
          cancelAnimationFrame(item.anima);
        });
      }
    };

    const liftMove = (moveData) => {
      //console.log("==============liftMove===============");
      if (modelFlag) {
        moves.value.forEach((item) => {
          // //console.log("ml[item.name]", ml[item.name]);

          const oldData = lastData.value[ml[item.name]] || {
            ActualPosition: 0,
          };
          const newData = moveData[ml[item.name]];
          const refresh = () => {
            if (item.flag) {
              item.frags.forEach((fragId) => {
                if (!stopFragId.value.includes(fragId)) {
                  let fragProxy = viewer.value.impl.getFragmentProxy(
                    viewer.value.model,
                    fragId
                  );

                  fragProxy.getAnimTransform();
                  let diffValue = 0;
                  if (newData && newData.ActualPosition) {
                    diffValue =
                      floorPos[newData.ActualPosition] -
                      floorPos[oldData.ActualPosition];
                  }

                  switch (true) {
                    case diffValue === 0:
                      cancelAnimationFrame(item.anima);
                      stopFragId.value.push(fragId);
                      break;
                    case diffValue > 0:
                      item.total = diffValue;
                      if (item.count < item.total) {
                        item.count += liftSpeed;
                        fragProxy.position.z += liftSpeed;
                      } else {
                        cancelAnimationFrame(item.anima);
                        stopFragId.value.push(fragId);
                      }
                      break;
                    case diffValue < 0:
                      item.total = diffValue;
                      if (item.count > item.total) {
                        item.count -= liftSpeed;
                        fragProxy.position.z -= liftSpeed;
                      } else {
                        cancelAnimationFrame(item.anima);
                        stopFragId.value.push(fragId);
                      }
                      break;
                    default:
                    //console.log("errors");
                  }
                  fragProxy.updateAnimTransform();
                }
              });
            }
            item.anima = requestAnimationFrame(refresh);
            viewer.value.impl.sceneUpdated(true);
          };
          refresh();
        });
        lastData.value = moveData;
      }
    };

    watch(
      () => currentModel.value?.moveData,
      (newVal, oldval) => {
        if (isObjectChanged(newVal, oldval)) {
          if (newVal && positionSet) {
            moves.value.forEach((item) => {
              item.count = 0;
              cancelAnimationFrame(item.anima);
            });
            stopFragId.value = [];
            liftMove(newVal);
          } else {
            //console.log(modelFlag);
            renders();
          }
        } else {
          //console.log("same value");
        }
      }
    );

    const renderRT = () => {
      if (ROTATE.flag) {
        ROTATE.frags.forEach((fragId, index) => {
          let quaternion = new THREE.Quaternion();
          quaternion.setFromAxisAngle(
            new THREE.Vector3(
              ROTATE.axis[index].x,
              ROTATE.axis[index].y,
              ROTATE.axis[index].z
            ),
            (ROTATE.right[index] ? Math.PI : -Math.PI) / 50
          );
          let fragProxy = viewer.value.impl.getFragmentProxy(
            viewer.value.model,
            fragId
          );
          fragProxy.getAnimTransform();

          let position = new THREE.Vector3(
            fragProxy.position.x - ROTATE.center[index].x,
            fragProxy.position.y - ROTATE.center[index].y,
            fragProxy.position.z - ROTATE.center[index].z
          );
          position.applyQuaternion(quaternion);
          position.add(ROTATE.center[index]);
          fragProxy.position = position;

          fragProxy.quaternion.multiplyQuaternions(
            quaternion,
            fragProxy.quaternion
          );
          fragProxy.updateAnimTransform();
        });
        anima = requestAnimationFrame(renderRT);
        viewer.value.impl.sceneUpdated(true);
      }
    };

    //获取树形菜单
    const fetchEquipmentTree = async (system_type = system_key) => {
      showToast(null, "提示", "正在获取设备列表");
      store
        .dispatch("jsf35/fetchEquipmentTree", {
          project_id: project_id,
          system_type,
        })
        .then((res) => {
          showToast(null, "成功", "设备列表获取成功");
          let list = {};
          res.data.list.map((equip_item) => {
            list[equip_item["equip_key"]] = equip_item;
          });
          equipmentList.value = list;
          store.commit("jsf35/setEquipList", res.data.list);
          store.commit("jsf35/setEquipTree", res.data.tree);
          showAllMarks(viewer.value);
        })
        .catch((error) => {
          showToast(error);
        });
    };

    //获取设备MeterCode列表
    const fetchEquipMeterCodeList = async (system_type = system_key) => {
      store
        .dispatch("jsf35/fetchEquipMeterCodeList", {
          project_id: project_id,
          system_type,
        })
        .then((res) => {
          store.commit("jsf35/setEquipMeterCodeList", res.data);
        })
        .catch((error) => {
          showToast(error);
        });
    };

    //重置模型
    const refreshModel = () => {
      initModel();
    };

    fetchEquipmentTree();
    fetchEquipMeterCodeList();

    //每分钟刷新一次数据
    const refreshInterval = ref(null);
    const refreshIntervalFn = () => {
      refreshInterval.value = setInterval(() => {
        fetchEquipmentTree();
        fetchEquipMeterCodeList();
      }, 1000 * 60);
    };

    onMounted(() => {
      initModel();
      refreshIntervalFn();
      checkPermission();
    });

    onUnmounted(() => {
      clearInterval(refreshInterval.value);
      //console.log("==========destroyModel============");
      cancelAnimationFrame(anima);
      // objResizeObserver.disconnect();
      if (moves.value) {
        moves.value.forEach((item) => {
          item.count = 0;
          cancelAnimationFrame(item.anima);
        });
      }
      destroyViewer(viewer.value);
      move.count = 0;
      move.speed = -100;
      move.frags = [];

      moves.value = [];

      ROTATE.center = [];
      ROTATE.frags = [];
      ROTATE.axis = [];
      ROTATE.right = [];

      modelFlag = false;
      positionSet = false;
    });

    return {
      simulation_dialog,
      electrical_dialog,
      MyAwesomeExtension,
      SelectModel,
      TurnTable,
      system_key,
      changeModel,
      equip_item,
      marksInfo,
      marks,
      ...toRefs(config),
      modelPath,
      modelIsDetail,
      render,
      rotate,
      // modalShow,
      explode_params,
      loading_show,
      showInfoCard,
      showMarksAction,
      switchModel,
      refreshModel,
      hasPermission,
      modelInfoCardShow,
    };
  },
};
</script>

<style lang="scss" scoped>
.simulation-dialog-in,.simulation-dialog-in {
  animation: bounceInRight 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 alternate
    forwards;
  -webkit-animation: bounceInRight 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1
    alternate forwards; /*Safari and Chrome*/
}

.simulation-dialog-out {
  animation: bounceOutRight 2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1
    alternate forwards;
  -webkit-animation: bounceOutRight 2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1
    alternate forwards; /*Safari and Chrome*/
}

@keyframes bounceInRight {
  0% {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  90% {
    transform: translate3d(5px, 0, 0);
  }
  100% {
    transform: none;
  }
}

@keyframes bounceOutRight {
  100% {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  90% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  60% {
    transform: translate3d(5px, 0, 0);
  }
  0% {
    transform: none;
  }
}

.c_app {
  height: 0;
}

.c_box {
  min-width: 5rem;
  background: rgba(79, 177, 230, 0.5);
  //border: 1px solid rgba(79, 177, 230, 1);
  border-radius: 0.5rem;
  position: relative;
  left: 40px;
  color: white;

  &::before {
    content: "";
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: rgb(79, 177, 230);
    position: absolute;
    opacity: 0;
    left: -44.8px;
    top: 319.2px;
    animation: color 1s infinite linear;
  }

  &::after {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: rgb(79, 177, 230);
    position: absolute;
    left: -44.4px;
    top: 187px;
  }

  /*animation: rota 4s infinite ease;*/
}

.c_box_alarm::before,
.c_box_alarm::after {
  background: rgb(234, 84, 85);
}

.c_box_run::before,
.c_box_run::after {
  background: rgb(40, 199, 111);
}

.c_content {
  position: relative;
  padding: 0.5rem 1rem;

  &::after {
    position: absolute;
    /*left: -14%;*/
    /*top: 25%;*/
    left: -20px;
    top: -3px;
    content: "";
    width: 1px;
    height: 40px;
    transform: rotate(90deg);
    background: rgb(79, 177, 230);
  }

  &::before {
    position: absolute;
    /*left: -28%;*/
    /*top: 50%;*/
    left: -40px;
    top: 17px;
    content: "";
    width: 1px;
    height: 180px;
    background: rgb(79, 177, 230);
  }
}

.c_title {
  font-weight: bold;
  font-size: larger;
  text-align: center;
  cursor: pointer;
}

.c_subtitle {
  text-align: center;
  font-size: smaller;
  margin-bottom: 0.5rem;
}

.c_mes {
  padding: 2px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@keyframes rota {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes color {
  from {
    width: 10px;
    height: 10px;
    opacity: 0.1;
    /*left: -31.3%;*/
    /*top: 230%;*/
    left: -44.4px;
    top: 187px;
  }

  to {
    width: 20px;
    height: 20px;
    opacity: 0.5;
    /*left: -35%;*/
    /*top: 225%;*/
    left: -49.4px;
    top: 182.5px;
  }
}
</style>
